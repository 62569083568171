// cSpell:ignore onvalidatemerchant, onpaymentmethodselected, onpaymentauthorized
import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  Dialog,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-final-form";
import { APIClient, gatewayProviderIds, getDomainFromFullUrl } from "../../lib";
import { setToast } from "../../state";
const APPLE_PAY_VERSION = 3;
const ERR_MSG =
  "We are having trouble connecting to Apple Pay, please try again or select a different payment method";

function _ApplePay({
  submitting,
  disableRetry,
  setDisplayingApplePay,
  needsPayment,
  gatewayProviderId,
  allow_apple_pay,
  apple_pay_merchant_id,
  orderTotal,
  orderHasDonationOrDonationItems,
  company_name,
  company_frontend_url,
  canadianCampaign,
}) {
  const form = useForm();
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const classes = styles();

  useEffect(() => {
    const { ApplePaySession } = window;
    if (
      (gatewayProviderId !== gatewayProviderIds.CARDKNOX &&
        gatewayProviderId !== gatewayProviderIds.AUTHORIZE_DOT_NET) ||
      disableRetry ||
      !needsPayment ||
      orderHasDonationOrDonationItems ||
      !allow_apple_pay ||
      !apple_pay_merchant_id ||
      !ApplePaySession ||
      !ApplePaySession.canMakePayments() ||
      !ApplePaySession.supportsVersion(APPLE_PAY_VERSION)
    ) {
      setShowButton(false);
      setDisplayingApplePay(false);
      return;
    }
    setShowButton(true);
    setDisplayingApplePay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gatewayProviderId,
    allow_apple_pay,
    apple_pay_merchant_id,
    needsPayment,
    orderHasDonationOrDonationItems,
    disableRetry,
  ]);

  useEffect(() => {
    if (!submitting) setShowDialog(false);
  }, [submitting]);

  const onSubmit = async applePayData => {
    setShowDialog(true);
    form.change("applePayData", applePayData);
    form.submit();
  };

  const onSessionError = (error, session) => {
    dispatch(setToast(ERR_MSG));
    try {
      session.abort();
    } catch (error) {
      console.error(error);
    }
    console.error(error);
  };

  const startApplePaySession = () => {
    let session;
    try {
      const ApplePaySession = window.ApplePaySession;
      if (!ApplePaySession || !ApplePaySession.canMakePayments()) {
        return;
      }

      session = new ApplePaySession(APPLE_PAY_VERSION, {
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        countryCode: canadianCampaign ? "CA" : "US",
        currencyCode: canadianCampaign ? "CAD" : "USD",
        total: { label: company_name, type: "final", amount: orderTotal },
      });

      session.onvalidatemerchant = async ({ validationURL }) => {
        const body = {
          merchantIdentifier: apple_pay_merchant_id,
          validationURL,
          displayName: company_name,
          initiativeContext: getDomainFromFullUrl(company_frontend_url),
        };
        try {
          const { data } = await APIClient.post("/apple_pay/get_session", body);
          session.completeMerchantValidation(data);
        } catch (error) {
          onSessionError(error, session);
        }
      };

      session.onpaymentmethodselected = () => {
        try {
          session.completePaymentMethodSelection({
            newTotal: {
              label: company_name,
              type: "final",
              amount: orderTotal,
            },
          });
        } catch (error) {
          onSessionError(error, session);
        }
      };

      session.onpaymentauthorized = data => {
        try {
          session.completePayment(session.STATUS_SUCCESS);
          onSubmit(data.payment.token.paymentData);
        } catch (error) {
          onSessionError(error, session);
        }
      };

      session.begin();
    } catch (error) {
      onSessionError(error, session);
    }
  };

  if (!showButton) return <></>;
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.or}>Or</div>
        <Button
          fullWidth
          className={classes.button}
          onClick={startApplePaySession}
        />
      </div>

      <Dialog open={showDialog} maxWidth={false}>
        <div className={classes.submittingDialog}>
          <div className={classes.submitting}>
            <div>Submitting...</div>
            <CircularProgress
              className={classes.spinner}
              color="secondary"
              size={50}
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}

export const ApplePay = connect(state => {
  const {
    campaign: {
      gatewayProviderId,
      allow_apple_pay,
      apple_pay_merchant_id,
      company_name,
      company_frontend_url,
      canadianCampaign,
    },
    product: { orderTotal, orderHasDonationOrDonationItems },
  } = state;
  return {
    gatewayProviderId,
    allow_apple_pay,
    apple_pay_merchant_id,
    orderTotal,
    orderHasDonationOrDonationItems,
    company_name,
    company_frontend_url,
    canadianCampaign,
  };
})(_ApplePay);

const styles = makeStyles(_theme => ({
  container: {
    marginTop: 16,
  },
  or: {
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 16,
  },
  button: {
    height: 56,
    borderRadius: 28,
    "-webkit-appearance": "-apple-pay-button",
    "-apple-pay-button-style": "black",
    "-apple-pay-button-type": "check-out",
  },
  submittingDialog: {
    backgroundColor: "#FFFFFF",
    width: 400,
    maxWidth: "100%",
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitting: {
    fontSize: 22,
    fontWeight: "bold",
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  spinner: {
    position: "absolute",
  },
}));
